export default {
  data() {
    return {
      _storageKey: 'timermixincommitedtime',
      _timer: null,
      timeLeft: 0,
    }
  },

  created() {
    this.timeLeft = this._getTimeLeft()
  },

  mounted() {
    this._setTimer()
  },

  beforeDestroy() {
    this._clearTimer()
  },

  methods: {
    commitTime() {
      this._setStorageTime(new Date())
    },
    _setTimer() {
      this._timer = setInterval(() => {
        this.timeLeft = this._getTimeLeft()
      }, 1000)
    },
    _clearTimer() {
      if (this._timer) {
        clearInterval(this._timer)
      }
    },
    _getStorageTime() {
      return sessionStorage.getItem(this._storageKey)
    },
    _setStorageTime(date) {
      sessionStorage.setItem(this._storageKey, date.toJSON())
    },
    _getTimeLeft() {
      const comittedTime = new Date(this._getStorageTime()).getTime()
      const now = new Date().getTime()
      const blockingTime = 60000

      let value = comittedTime + blockingTime - now

      value = Math.round(value / 1000)

      return value > 0 ? value : 0
    },
  },
}
