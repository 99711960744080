export default {
  watch: {
    actionButton(value) {
      const actionButton = this.$store.state.actionButton;
      if (value !== actionButton) {
        this.$store.commit("actionButton/update", value);
      }
    }
  },

  mounted() {
    this.$store.commit("actionButton/update", this.actionButton);
  },

  destroyed() {
    this.$store.commit("actionButton/clear");
  }
};
