<template>
  <div class="text-center mb-4">
    <div class="mb-4">
      <div class="heading text-center dark--text">
        Дождитесь входящего звонка
      </div>
      <div class="mt-2">
        Введите {{ staticFieldsCodes.length }} последних цифр номера, с которого
        вам позвонили
      </div>
    </div>
    <v-row align="start" dense class="flex-nowrap">
      <v-col v-for="(fieldCode, i) in staticFieldsCodes" :key="i" cols="3">
        <label for="form-field-code">
          <!-- v-stream:input="{
              subject: fieldsCodes$,
              data: i,
              options: { once: false, passive: false, capture: false },
            }" -->

          <v-text-field
            id="form-field-code"
            ref="fieldCode"
            @input.passive="handleInputEvent(i, $event)"
            class="form-field-code"
            outlined
            hide-details
            type="number"
            :minlength="1"
            :maxlength="1"
            :value="fieldCodes && fieldCodes.value.get(i)"
            :aria-selected="fieldCodes && fieldCodes.data === i"
            :aria-valuenow="fieldCodes && fieldCodes.value.get(i)"
            :max="9"
            :min="0"
            autocomplete="off"
            aria-valuemax="9"
            aria-valuemin="0"
            aria-hidden="false"
            aria-label="common-fields-code"
            aria-controls="static-field-code"
          />
        </label>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import format from 'string-format'

import { range } from '@/utils/range-array'

import { coerceNumberProperty } from '@/utils/coercion/number-property'

import {
  distinctUntilChanged,
  EMPTY,
  iif,
  map,
  mapTo,
  mergeMap,
  of,
  pluck,
  ReplaySubject,
  skipWhile,
  switchMap,
  take,
  takeUntil,
  tap,
  throwIfEmpty,
  withLatestFrom,
} from 'rxjs'

const FlashCallModalConstant = Symbol.for('FlashCallModal')

/**
 * Default Confirmation Code Amount.
 */
const EMPTY_STRING_CHAR_CODE = 32

/**
 * Default Confirmation Code Amount.
 */
const SMS_GATEWAY_FIELD_CODE_LENGTH_FACTORY = 4

/**
 * Field Code Length Exceeded Error.
 *
 * @class FieldCodeLengthExceededError
 * @extends RangeError
 */
class FieldCodeLengthExceededError extends RangeError {
  // eslint-disable-next-line no-useless-constructor

  constructor(message) {
    super(message)
  }
}

/**
 * Field Code Process Handler.
 *
 * @class FieldCodeHandler
 */
class FieldCodeHandler {
  source

  constructor(source) {
    this.source = source
  }

  process(event) {
    const data = (event.data && event.data.slice(0, 1)) || null
    const targetValue = coerceNumberProperty(data)
    event.target.value = targetValue
  }
}

/**
 * Multi Codes Factory.
 *
 * @internal method.
 */
const fieldsCodesFillFactory = (
  length = SMS_GATEWAY_FIELD_CODE_LENGTH_FACTORY
) => {
  const fieldsCodes = []

  for (const fieldCode of range(length)) fieldsCodes.push([fieldCode, null])

  return new Map(fieldsCodes)
}

export default {
  name: Symbol.keyFor(FlashCallModalConstant),
  comments: false,
  props: {
    code: {
      type: [String, Number],

      required: true,

      default: () => null,
    },
  },

  domStreams: ['fieldsCodes$'],

  subscriptions() {
    this.commonFieldsCode$ = new ReplaySubject()

    // @internal fields codes overrides to common fields code.

    return {
      fieldCodes: this.fieldsCodes$.pipe(
        pluck('event'),

        tap((event) => new FieldCodeHandler(this).process(event)),

        pluck('target', 'value'),

        mergeMap((value) =>
          iif(
            () =>
              value &&
              value.length === 1 &&
              value.charCodeAt(0) !== EMPTY_STRING_CHAR_CODE,

            of(value),

            EMPTY
          )
        ),

        throwIfEmpty(
          (value) =>
            new FieldCodeLengthExceededError(
              format('Field code was exceeded with value: {0}', value)
            )
        ),

        mapTo(fieldsCodesFillFactory()),

        withLatestFrom(this.fieldsCodes$),

        switchMap(([value, { data, event }]) => {
          return value instanceof Map && value.has(data)
            ? of([value, { data, targetValue: event.target.value, event }])
            : EMPTY
        }),

        distinctUntilChanged(),

        map(([value, { data, targetValue, event }]) => {
          value.set(data, targetValue)
          return { value, data, event }
        }),

        tap(({ value, data, event }) => {
          const index =
            event.inputType === 'deleteContentBackward' ? data - 1 : data + 1
          if (this.getHostElement(index)) this.getHostElement(index)?.focus()
          this.commonFieldsCode$.next(value)
        })
      ),
    }
  },
  methods: {
    handleInputEvent(data, event) {
      if (event && event.target) this.fieldsCodes$.next({ data, event })
    },
  },
  computed: {
    staticFieldsCodes() {
      return range(SMS_GATEWAY_FIELD_CODE_LENGTH_FACTORY)
    },

    getHostElement() {
      return (index) => {
        const elementRef = this.$refs.fieldCode && this.$refs.fieldCode[index]
        return elementRef ?? null
      }
    },
  },

  mounted() {
    // eslint-disable-next-line no-unused-vars

    const destroySubject = this.$eventToObservable('hook:beforeDestroy').pipe(
      take(1)
    )

    // @internal subscribe to common fields code listener.

    this.$subscribeTo(
      this.commonFieldsCode$.pipe(
        map((values) =>
          // eslint-disable-next-line no-unused-vars
          Array.from(values, ([name, value]) => value).filter(
            (value) => value && value.charCodeAt(0) !== EMPTY_STRING_CHAR_CODE
          )
        ),

        skipWhile((values) => {
          return values.length < SMS_GATEWAY_FIELD_CODE_LENGTH_FACTORY
        }),

        map((value) => value && value.join('')),

        takeUntil(destroySubject)
      ),

      (value) => this.$emit('update:code', value)
    )
  },
}
</script>


<style lang="scss" scoped>
.form-field-code::v-deep input {
  text-align: center !important;
}
</style>