/**
 * Coerce number property for the handler.
 *
 * @export
 * @param  {any} value
 * @param  {number} [fallbackValue=0]
 * @return
 */
export function coerceNumberProperty(value, fallbackValue = 0) {
  return _isNumberValue(value) ? Number(value) : fallbackValue
}

/**
 * Check out is number value valid or not.
 *
 * @export
 * @param  {any} value
 * @return
 */
export function _isNumberValue(value) {
  return !isNaN(parseFloat(value)) && !isNaN(Number(value))
}
